import React from "react";
import styles from "./Institutions.module.css"

import unifei from "../../images/institutions/unifei-2d.png"
import imc from "../../images/institutions/imc-square.png"
import imcHorizontal from "../../images/institutions/imc.png"
import iesti from "../../images/institutions/iesti.png"
import dacomp from "../../images/institutions/dacomp.png"

export default function Institutions() {
  return (
    <section className={styles.sponsors}>
      <img className={styles.sponsorLogo} src={unifei} alt="Logotipo da UNIFEI" />
      <img className={styles.sponsorLogo} src={imc} alt="Logotipo do Instituto de Matemática e Computação" />
      <img className={styles.sponsorLogo} src={iesti} alt="Logotipo do Instituto de Engenharia e Sistema em Tecnologia de Informação" />
      <img className={styles.sponsorLogo} src={dacomp} alt="Logotipo do Diretório Acadêmico de Computação" />

      <img className={styles.imcHorizontalLogo} src={imcHorizontal} alt="Logotipo do Instituto de Matemática e Computação" />
    </section>
  )
};
