import React from "react";
import styles from "../styles/OrganizadoresLista.module.css";

export default function OrganizadoresLista() {
  return (
    <div className={styles.orgLista}>
      <div className={styles.titulo}>Coordenação</div>
      <ul className={styles.lista}>
        <li>{"BARBARA PIMENTA CAETANO".toLowerCase()}</li>
        <li>{"BRUNO GUAZZELLI BATISTA".toLowerCase()}</li>
        <li>{"FLÁVIO BELIZÁRIO DA SILVA MOTA".toLowerCase()}</li>
        <li>{"ISABELA NEVES DRUMMOND".toLowerCase()}</li>
        <li>{"MELISE MARIA VEIGA DE PAULA".toLowerCase()}</li>
        <li>{"OTÁVIO DE SOUZA MARTINS GOMES".toLowerCase()}</li>
        <li>{"PHYLLIPE DE SOUZA LIMA FRANCISCO".toLowerCase()}</li>
        <li>{"VANESSA CRISTINA OLIVEIRA DE SOUZA".toLowerCase()}</li>
      </ul>
      <div className={styles.titulo}>Colaboração</div>
      <ul className={styles.lista}>
        <li>{"ALEJANDRO MACHA PAIVA".toLowerCase()}</li>
        <li>{"ANA CLARA DA COSTA CAETANO".toLowerCase()}</li>
        <li>{"ANA MAÍSA DO NASCIMENTO SANTOS".toLowerCase()}</li>
        <li>{"AUGUSTO DE CAMARGOS ZANOLI".toLowerCase()}</li>
        <li>{"CAMILA MOTTA RENÓ".toLowerCase()}</li>
        <li>{"CAROLINA ROCHA TENORIO DE CARLES".toLowerCase()}</li>
        <li>{"CLARA CARDOSO BOREM GUIMARÃES".toLowerCase()}</li>
        <li>{"Eduardo Siles Martins".toLowerCase()}</li>
        <li>{"ERIKA APARECIDA SILVA OLIVEIRA".toLowerCase()}</li>
        <li>{"GABRIEL CARNEIRO GUEDES".toLowerCase()}</li>
        <li>{"Giovanna Xavier Silverio".toLowerCase()}</li>
        <li>{"GUILHERME TEODORO MOREIRA LEITE".toLowerCase()}</li>
        <li>{"HELLEN FERNANDA XAVIER DA SILVEIRA".toLowerCase()}</li>
        <li>{"JOEL VÍTOR TORRES DE ANDRADE PINTO".toLowerCase()}</li>
        <li>{"KELLY DOS REIS LEITE".toLowerCase()}</li>
        <li>{"PEDRO NOGUEIRA BARBOZA".toLowerCase()}</li>
        <li>{"RODRIGO NASCIMENTO PEREIRA".toLowerCase()}</li>
        <li>{"Talles Alves de Morais".toLowerCase()}</li>
      </ul>
    </div>
  );
}
