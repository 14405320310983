import React from 'react'
import styles from '../styles/Footer.module.css';
import devbsol from "../images/devbsol.png";

function Footer() {
  return (
    <div className={styles.footer}>
        <div className={styles.copyright}>
          <img src={devbsol} alt="Desenvolvido por byron.solutions" className={styles.img}/>
        </div>
    </div>
  )
}

export default Footer