import React from "react";
import styles from "./EventModalMessage.module.css"

interface EventModalMessageProps {
  status: number,
  activity: any,
  errorMessage: string,
  onClick: () => void,
}

export default function EventModalMessage({ status, activity, errorMessage, onClick }:EventModalMessageProps) {
  return (
    <>
      {status === 201 &&
        <div className={styles.messageContainer}>
          <h1 className={styles.title}>Inscrição realizada!</h1>
          <p className={styles.successfulMessage}>Inscrição na atividade <span className={styles.activityName}>{activity.name.toUpperCase()}</span> realizada com sucesso!</p>
          <p>Use o mesmo QR code recebido na inscrição da SECOMP para validar a sua presença e garantir os certificados!</p>
          <p>Te esperamos lá!</p>
          <button className={styles.goBackBtn} onClick={onClick}>Voltar</button>
        </div>
      }

      {status === 400 &&
        <div className={styles.messageContainer}>
          <h1 className={styles.title}>Erro!</h1>
          <p className={styles.errorMessage}>
            Ocorreu um erro na inscrição da atividade!
          </p>
          <p className={styles.errorDescription}>
            {errorMessage}
          </p>
          <p>
            Verifique se já está inscrito nesta atividade ou em algum outro minicurso.
          </p>
          <button className={styles.goBackBtn} onClick={onClick}>
            Voltar
          </button>
        </div>
      }

      {status === 403 &&
        <div className={styles.messageContainer}>
          <h1 className={styles.title}>Erro!</h1>
          <p className={styles.errorMessage}>
            Ocorreu um erro na inscrição da atividade!
          </p>
          <p className={styles.errorDescription}>
            {errorMessage}
          </p>
          <p>
            Verifique se já está inscrito nesta atividade ou em algum outro minicurso.
          </p>
          <button className={styles.goBackBtn} onClick={onClick}>
            Voltar
          </button>
        </div>
      }

      {status === 404 &&
        <div className={styles.messageContainer}>
          <h1 className={styles.title}>Erro!</h1>
          <p className={styles.errorMessage}>
            Ocorreu um erro na inscrição da atividade!
          </p>
          <p className={styles.errorDescription}>
            {errorMessage}
          </p>
          <p>
            Verifique se já está inscrito nesta atividade ou em algum outro minicurso.
          </p>
          <button className={styles.goBackBtn} onClick={onClick}>
            Voltar
          </button>
        </div>
      }
      {status === 409 &&
        <div className={styles.messageContainer}>
          <h1 className={styles.title}>Erro!</h1>
          <p className={styles.errorMessage}>
            Ocorreu um erro na inscrição da atividade!
          </p>
          <p className={styles.errorDescription}>
            {errorMessage}
          </p>
          <p>
            Verifique se já está inscrito nesta atividade ou em algum outro minicurso.
          </p>
          <button className={styles.goBackBtn} onClick={onClick}>
            Voltar
          </button>
        </div>
      }
      {status === 500 &&
        <div className={styles.messageContainer}>
          <h1 className={styles.title}>Erro!</h1>
          <p className={styles.errorMessage}>
            Ocorreu um erro na inscrição da atividade!
          </p>
          <p className={styles.errorDescription}>
            {errorMessage}
          </p>
          <p>
            Verifique se já está inscrito nesta atividade ou em algum outro minicurso.
          </p>
          <button className={styles.goBackBtn} onClick={onClick}>
            Voltar
          </button>
        </div>
      }

      {status !== 201 && status !== 400 && status !== 403 && status !== 404 && status !== 409 && status !== 500 && (
        <div className={styles.messageContainer}>
          <h1 className={styles.title}>Erro!</h1>
          <p className={styles.errorMessage}>
            Ocorreu um erro inesperado. Tente novamente mais tarde.
          </p>
          <p>
            Verifique se já está inscrito nesta atividade ou em algum outro minicurso.
          </p>
          <button className={styles.goBackBtn} onClick={onClick}>
            Voltar
          </button>
        </div>
      )}
    </>
  )
};
