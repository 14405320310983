import styles from "../styles/Organizadores.module.css";
import React, { useState } from "react";
import DatasContainerOrg from "./DatasContainerOrg.jsx";
import ConteudoContainerOrg from "./ConteudoContainerOrg.jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Organizadores() {
  const [selectedIndex, setSelectedIndex] = useState(1);

  return (
    <section id="organizadores" className={styles.organizadores}>
      <h2 className={styles.titulo}>Palestrantes</h2>
      <div className={styles.datasContainer}>
        {/* <DatasContainerOrg
          selectedIndex={selectedIndex}
          dataIndex={0}
          setSelectedIndex={setSelectedIndex}
        ></DatasContainerOrg> */}
        <DatasContainerOrg
          selectedIndex={selectedIndex}
          dataIndex={1}
          setSelectedIndex={setSelectedIndex}
        ></DatasContainerOrg>
        <DatasContainerOrg
          selectedIndex={selectedIndex}
          dataIndex={2}
          setSelectedIndex={setSelectedIndex}
        ></DatasContainerOrg>
        <DatasContainerOrg
          selectedIndex={selectedIndex}
          dataIndex={3}
          setSelectedIndex={setSelectedIndex}
        ></DatasContainerOrg>
      </div>
      <div className={styles.organizadoresContainer}>
        <ConteudoContainerOrg selectedIndex={selectedIndex} />
      </div>
    </section>
  );
}
