import React from "react"

import addIcon from "../../images/icons/add.svg"
import styles from "./EventCard.module.css"

interface EventProps {
  title: string,
  description: string,
  slots: number
  available: boolean
  onClick?: () => void;
}

export default function EventCard({ title, description, slots, available, onClick }: EventProps) {
  function handleCardState() {
    if (slots && available) {
      return styles.slotsBadge
    } else if (!slots && available) {
      return styles.slotsBadgeEmpty
    } else if (!available) {
      return styles.slotsBadgeDisabled
    }
  }

  return (
    <div className={available ? styles.cardContainer : styles.cardContainerDisabled} onClick={onClick}>
      <h1 className={styles.title}>{title}</h1>

      <div className={styles.descriptionContainer}>
        <h2>Descrição:</h2>
        <p className={styles.description}>{description}</p>
      </div>

      <div className={handleCardState()}>
        <p>{available ? `${slots ?? 0} vagas` : `Indisponível`}</p>
      </div>

      <button className={styles.moreInfoBtn}>
        <img src={addIcon} alt="Simbolo do operador de soma" />
        Saiba mais
      </button>
    </div>
  )
};
