import React from "react";
import styles from "../styles/Hero.module.css";
import blockgroups from "../images/BlockGroups.png";
import "../fonts/ProductSans/ProductSans-Black.ttf";
import "../fonts/ProductSans/ProductSans-Light.ttf";
import "../fonts/ProductSans/ProductSans-Medium.ttf";
import Botao from "./Botao";
import clique from "../images/clique.png";

function Hero() {
  return (
    <section id="hero" className={styles.hero}>
      <div className={styles.content}>
        <div className={styles.intro}>
          <div className={styles.textIntro}>
            <h1 className={styles.textTitle}>II Semana da Computação</h1>
            <p className={styles.text}>
              {`A II Semana da Computação da UNIFEI (SECOMP 2024) é um evento idealizado pelo DAComp e professores dos Cursos de Ciência da Computação, Sistemas de Informação e Engenharia da Computação da Universidade Federal de Itajubá - UNIFEI, Campus de Itajubá que acontecerá nos dias 01 à 04 de Setembro de 2024. Em sua segunda edição, a SECOMP 2024, contará com Palestras, Minicursos, Rodada de Estágio, Sessão de Pôsteres, entre outras atividades, reunindo especialistas, acadêmicos e entusiastas da computação para promover discussões e compartilhar avanços na área.\n`}
              <span style={{ fontWeight: "bold", display: "block"}}>
                {`\nO evento é gratuito e vale horas de atividade complementar!\n`}
                {`\nSejam todos bem-vindos!`}
              </span>
            </p>
            <Botao
              internLink
              url={"/subscribe"}
              texto={"Inscreva-se já!"}
              img={clique}
            />
          </div>
        </div>
        <img
          src={blockgroups}
          alt="blockgroups"
          className={styles.blockgroups}
        />
      </div>
    </section>
  );
}

export default Hero;
