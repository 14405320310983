import React from "react";
import styles from "../styles/ConteudoContainer.module.css";
import icon from "../images/Icon.svg";
import arrow from "../images/arrow.svg";

import luiz from "../images/speakers/luiz.jpg";
import guilherme from "../images/speakers/guilherme.png";
import paulo from "../images/speakers/paulo.jpg";
import renata from "../images/speakers/renata.jpg";
import ana from "../images/speakers/ana.jpg";
import flavia from "../images/speakers/flavia.jpeg";
import rodrigo from "../images/speakers/rodrigo.png";
import matheus from "../images/speakers/matheus.webp";
import felipe from "../images/speakers/felipe.png";
import gustavo from "../images/speakers/gustavo.jpg";
import virginia from "../images/speakers/virginia.png";
import joao from "../images/speakers/joao.png";
import ikaro from "../images/speakers/ikaro.jpeg";
import mauricio from "../images/speakers/mauricio.jpeg";
import bernardo from "../images/speakers/bernardo.jpg";
import ytalo from "../images/speakers/ytalo.jpg";
import gianluca from "../images/speakers/gianluca.jpeg";
import wendel from "../images/speakers/wendel.jpg";

export default function ConteudoContainer(props) {
  const renderDescricao = () => {
    switch (props.selectedIndex) {
      case 0:
        return (
          <div className={styles.conteudo}>
            <div className={styles.grid6}>
              <div className={styles.descricaoContainer}>
                <div className={styles.descricaoTitulo}>
                  <img src={arrow} className={styles.arrow} />
                  <div>09:00 - Hackathon de dados</div>
                </div>
                <div className={styles.descricao}>
                  <p>
                    Participe do hackathon de dados da Descubra e crie painéis
                    interativos sobre as emissões de gases de efeito estufa no
                    Brasil. O desafio é explorar, analisar e visualizar dados,
                    ajudando a transformar informações, contribuindo para um
                    futuro mais sustentável.
                  </p>
                  <a
                    className={styles.speakerInfo}
                    target="_blank"
                    href="https://descubra-solucoes.github.io/hackathon/"
                  >
                    Clique aqui para maiores informações
                  </a>
                  <p className={styles.highlight}>
                    Local: LDC-1 (Instituto de Matemática e Computação)
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case 1:
        return (
          <div className={styles.grid6}>
            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>14:00 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>Ambiente de desenvolvimento moderno e Opensource</b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/luizfelipecosta/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={luiz}
                    alt="Fotografia do palestrante Luiz"
                  />
                  <i>Luiz Felipe Fernandes Machado Costa (Red Hat)</i>
                </a>
                <p>
                  O desenvolvimento de aplicações modernas exige um ecossitema
                  grande de aplicações, como Sistema Operacional, IDEs,
                  compiladores, linters, gerencioadores de pacotes, entre
                  outros. Comunidades opensource e plataformas gratuitas podem
                  ser uma excelente porta de entrada para desenvolver sistemas
                  de ponta, com o melhor que existem em funcionalidades e
                  seguraça.
                </p>
                <p className={styles.highlight}>Local: Auditório da Elétrica</p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>15:00 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>
                  Desvendando o Docker: Fundamentos para Desenvolvedores e
                  Equipes de TI
                </b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/guilherme-ferreira-30141548/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={guilherme}
                    alt="Fotografia do palestrante Guilherme"
                  />
                  <i>Guilherme Augusto Ferreira (Turing - Machine Metrics)</i>
                </a>
                <p>
                  Compreender os fundamentos do Docker e seus benefícios. Criar,
                  gerenciar e orquestrar containers Docker com confiança.
                  Aplicar o Docker em seus projetos para otimizar o
                  desenvolvimento e o deployment.
                </p>
                <p className={styles.highlight}>Local: Auditório da Elétrica</p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>16:00 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>Impacto da IA no desenvolvimento de software moderno</b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/paulo-antonio-ceravolo-rodrigues/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={paulo}
                    alt="Fotografia do palestrante Paulo"
                  />
                  <i>Paulo Antonio Cerávolo Rodrigues (Cognizant)</i>
                </a>
                <p>
                  Exploremos como a inteligência artificial está revolucionando
                  o desenvolvimento de software. Abordaremos a evolução
                  histórica da IA, destacando sua capacidade de automatizar e
                  otimizar processos, integrar funcionalidades inteligentes e
                  enfrentar desafios técnicos e éticos. Discutiremos também as
                  tendências futuras e inovações esperadas. O objetivo é
                  fornecer uma visão abrangente e prática sobre como a IA está
                  moldando o futuro da indústria de software e trocar
                  experiências sobre como a IA já está impactando a indústria e
                  a educação.
                </p>
                <p className={styles.highlight}>Local: Auditório da Elétrica</p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>19:00 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>
                  Vamos ampliar nossa visão sobre Sistemas de Informação e sobre
                  a Computação também?
                </b>
                <a
                  target="_blank"
                  href="http://lattes.cnpq.br/3589012014320121"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={renata}
                    alt="Fotografia do palestrante Renata"
                  />
                  <i>
                    Renata Mendes Araújo (Universidade Presbiteriana Mackenzie)
                  </i>
                </a>
                <p>
                  Esta palestra pretende refletir sobre o dilema disposto na
                  contemporaneidade em relação à forma como concebemos e
                  estudamos os sistemas de informação. Conforme os sistemas de
                  informação assumem papéis cada vez mais fundamentais na vida
                  em sociedade, na governança pública e na transformação dos
                  negócios, torna-se cada vez mais evidente a necessidade de
                  praticarmos o estudo de sistemas de informação sob uma nova
                  (ou seria velha?) perspectiva. Passamos muito tempo fazendo o
                  movimento de abstrair, reduzir, controlar e objetivar, para
                  construir sistemas. Parece que agora é hora de nos movermos ao
                  contrário: ampliar, subjetivar, refletir e observar, para
                  projetar soluções de impacto no mundo em que vivemos.
                </p>
                <p className={styles.highlight}>Local: Auditório da Elétrica</p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>20:00 - Cerimônia de abertura</div>
              </div>
              <div className={styles.descricao}>
                <p className={styles.highlight}>
                  Local: Auditório da Elétrica
                </p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>20:30 - Exposição</div>
              </div>
              <div className={styles.descricao}>
                <b>Exposição sobre projetos especiais e empresas juniores </b>
                <p>
                  A exposição de projetos especiais e empresas juniores é um
                  espaço onde estudantes podem conhecer de perto iniciativas
                  inovadoras e práticas que estão rolando dentro da
                  universidade. Durante a exposição, as empresas juniores e os
                  grupos de projetos especiais montam stands para apresentar o
                  que estão desenvolvendo, desde soluções tecnológicas até
                  empreendimentos criativos. É uma ótima oportunidade para ver o
                  que seus colegas estão criando, trocar ideias, se inspirar, e
                  até encontrar parceiros para futuras colaborações. É o lugar
                  perfeito para quem quer se conectar com o que há de mais atual
                  e empreendedor na universidade!
                </p>
                <p className={styles.highlight}>
                  Local: Salão de Apoio da Reitoria
                </p>
              </div>
            </div>
          </div>
        );
        break;
      case 2:
        return (
          <div className={styles.grid6}>
            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>10:30 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>
                Além do básico: Explorando a complexidade do desenvolvimento web em larga escala
                </b>

               

                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/wnascimentow/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={wendel}
                    alt="Fotografia do palestrante Wendel"
                  />
                  <i>Wendel Nascimento (Uber)</i>
                </a>

                

                <a
                  target="_blank"
                  href="https://www.youtube.com/watch?v=ZOLm_j53Pzk"
                  className={styles.speakerInfo}
                >
                  <i>Live no Youtube</i>
                </a>
                <p>
                Quando falamos de desenvolvimento web logo pensamos em sites ou aplicações mais simples para gestão de um backoffice ou algo do tipo. Nessa apresentação irei compartilhar um pouco do quão complexo pode ser o ecossistema que sustenta e permeia uma aplicação web, principalmente quando se trata de níveis de escala como o de uma empresa como a Uber, por exemplo. Irei falar um pouco sobre alguns desafios que geralmente não são muito comentados e que só acabamos descobrindo quando de fato nos deparamos com situações específicas ao longo da jornada de desenvolvimento.
                </p>
                <p className={styles.highlight}>Local: Online</p>
              </div>
            </div>


            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>14:00 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>
                  Da computação à aviação: uma jornada empreendedora na Unifei
                </b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/anaraquelcp/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={ana}
                    alt="Fotografia do palestrante Ana"
                  />
                  <i>Ana Raquel Calhau (NexAtlas)</i>
                </a>
                <p>
                  Nesse bate-papo descontraído, Ana Raquel Calhau compartilha os
                  altos e baixos de sua jornada empreendedora com a NexAtlas -
                  startup no setor aeronáutico que acaba de receber
                  investimento. Essa aventura, vivenciada pelos corredores do
                  Centro de Empreendedorismo Unifei durante o curso de Sistemas
                  de Informação, mostra um pouco do que é o empreendedorismo
                  universitário, e ainda compartilha dicas de como empreender,
                  criar conexões valorosas e como aproveitar ao máximo a
                  graduação para alavancar sua própria empresa.
                </p>
                <p className={styles.highlight}>Local: Auditório da Elétrica</p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>14:40 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>Como me preparar para o Mercado de Trabalho?</b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/fl%C3%A1via-rangel-98851069/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={flavia}
                    alt="Fotografia do palestrante Flavia"
                  />
                  <i>Flavia Rangel (Uber)</i>
                </a>
                <p>
                  Durante essa palestra passaremos por temas como, possíveis
                  especializações da area de Tech que alguém entrando no Mercado
                  de Trabalho pode escolher, como me preparar para uma
                  entrevista em uma Big Tech.
                </p>
                <p className={styles.highlight}>Local: Auditório da Elétrica</p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>15:20 - Mesa Redonda</div>
              </div>
              <div className={styles.descricao}>
                <p className={styles.highlight}>Local: Auditório da Elétrica</p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>16:30 - Rodada de Estágio</div>
              </div>
              <div className={styles.descricao}>
                <p>
                  Uma rodada de estágios é um evento dinâmico onde empresas e
                  instituições se reúnem para apresentar suas oportunidades de
                  estágio a estudantes. Durante a rodada, os participantes têm a
                  chance de conhecer diferentes empresas, descobrir vagas,
                  entender o perfil que elas procuram e, em alguns casos, até
                  participar de processos seletivos no próprio evento. É uma
                  excelente oportunidade para quem está buscando ingressar no
                  mercado de trabalho, fazer networking e encontrar um estágio
                  que combine com suas habilidades e interesses.
                </p>
                <p className={styles.highlight}>Local: Auditório do IMC</p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>19:00 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>
                  Inteligência Artificial no Bing da Microsoft: Uma visão geral
                </b>
                
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/rtoso/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={rodrigo}
                    alt="Fotografia do palestrante Rodrigo"
                  />
                  <i>Rodrigo Franco Toso (Microsoft)</i>
                </a>

                <a
                  target="_blank"
                  href="https://www.youtube.com/watch?v=VxaylWYW3tQ"
                  className={styles.speakerInfo}
                >
                  <i>Live no Youtube</i>
                </a>

                <p>
                  Nesta palestra, vamos discutir alguns problemas envolvendo
                  Inteligência Artificial (IA) no Bing da Microsoft: ativação de
                  respostas instantâneas e remoção de respostas defeituosas. No
                  primeiro caso, empregamos técnicas tradicionais de ML
                  combinadas com dados massivos para acionar a resposta do Bing
                  Chat no Bing. No segundo, utilizamos o poder dos modelos de
                  linguagem LLMs e juízes terceirizados para rotular dados e nos
                  ajudar a ajustar nossos prompts para alcançar uma redução de
                  5% nos defeitos de respostas no Bing.
                </p>
                <p className={styles.highlight}>Local: Online</p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>19:00 - Minicurso</div>
              </div>
              <div className={styles.descricao}>
                <b>Frontend com Angular (framework do Google)</b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/maths-oliveira/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={matheus}
                    alt="Fotografia do palestrante Matheus"
                  />
                  <i>Matheus Henrique de Oliveira (Questrade)</i>
                </a>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {`Este minicurso oferece uma imersão prática no desenvolvimento frontend utilizando o Angular, um dos frameworks mais populares para construção de aplicações web modernas. O minicurso cobre desde os fundamentos básicos de desenvolvimento web até conceitos avançados em Angular (de acordo com o tempo e a audiência).
              
                      O que você vai aprender:
                      - Fundamentos do Desenvolvimento Web: Explore os conceitos básicos de HTML, CSS e JavaScript para garantir uma base sólida e permitir que todos os participantes, independentemente do nível de conhecimento prévio, acompanhem o conteúdo do minicurso.
                      - Fundamentos do Angular: Entenda a estrutura e a arquitetura do Angular, incluindo módulos, componentes e serviços.
                      - Desenvolvimento de Interfaces: Aprenda a criar interfaces interativas utilizando Angular CLI e Angular Material.
                      - Gerenciamento de Estado: Explore técnicas para gerenciar o estado da aplicação e otimizar o fluxo de dados entre componentes.
                      - Integração com APIs: Descubra como conectar suas aplicações a APIs externas e manipular dados em tempo real.
                      - Boas Práticas: Aprofunde-se nas melhores práticas de desenvolvimento, com foco na construção de aplicações robustas e eficientes.

                      Para quem é este minicurso:
                      Este minicurso é ideal para pessoas que têm interesse no desenvolvimento web e não sabem como iniciar, bem como para os já experientes em frontend que buscam expandir suas competências com este framework robusto que é o Angular. A inclusão dos fundamentos básicos de desenvolvimento web assegura que todos possam acompanhar e aproveitar o conteúdo do minicurso.

                      Ao final do minicurso, os participantes estarão preparados para criar e manter aplicações web dinâmicas, utilizando Angular para oferecer uma experiência de usuário excepcional.

                      Nota: Testes de software não serão cobertos neste minicurso.`}
                </p>
                <p className={styles.highlight}>
                  Local: LDC-6 (Instituto de Matemática e Computação)
                </p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>19:00 - Minicurso</div>
              </div>
              <div className={styles.descricao}>
                <b>Chatbots Inteligentes</b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/felipe-souza-87a10155/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={felipe}
                    alt="Fotografia do palestrante Felipe"
                  />
                  <i>Felipe Souza (Zeiss Vision Care)</i>
                </a>
                <p>
                  Apresentaremos como podemos utilizar a tecnologia para
                  melhorar a experiência dos clientes deixando de utilizar
                  plataformas com o formato de "URA" e passando a utilizar
                  modelos inteligentes com ferramentas disponíveis e com baixo
                  custo, além de totalmente personalizável e a utilização de
                  técnicas que podem levá-lo ao aprendizado contínuo. Vamos
                  falar um pouco sobre os famosos "Guard Rails" que devem ser
                  preparados para evitar uso indevido e como integrar com
                  sistemas existentes.
                </p>
                <p className={styles.highlight}>
                  Local: LDC-2 (Instituto de Matemática e Computação)
                </p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>19:00 - Minicurso</div>
              </div>
              <div className={styles.descricao}>
                <b>Conhecendo o bubble, usando o low-code na criação de MVPs</b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/sallesdev/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={gustavo}
                    alt="Fotografia do palestrante Gustavo"
                  />
                  <i>Gustavo Salles (UNIFEI)</i>
                </a>
                <p>
                  Neste minicurso, serão apresentados os conceitos básicos de
                  desenvolvimento low-code e da plataforma Bubble. Exploraremos
                  como utilizar os recursos gratuitos da ferramenta para criar
                  um MVP. O curso incluirá uma introdução à criação de telas no
                  front-end, demonstrando como utilizar componentes da
                  comunidade e estilizar elementos. Também exploraremos o banco
                  de dados do Bubble, abordando tipos de dados e como trabalhar
                  com dados de forma dinâmica. Finalmente, será apresentada a
                  funcionalidade de workflow, incluindo a criação de gatilhos e
                  eventos que podem ser configurados para automatizar processos
                  dentro da plataforma.
                </p>
                <p className={styles.highlight}>
                  Local: LDC-1 (Instituto de Matemática e Computação)
                </p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>19:00 - Minicurso</div>
              </div>
              <div className={styles.descricao}>
                <b>
                  Desenvolvimento Mobile - Overview do Zero até a Publicação
                </b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/paulo-antonio-ceravolo-rodrigues/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={paulo}
                    alt="Fotografia do palestrante Paulo"
                  />
                  <i>Paulo Antonio Cerávolo Rodrigues (Cognizant)</i>
                </a>
                <p>
                  O mini-curso "Desenvolvimento Mobile - Overview do Zero até a
                  Publicação" oferece uma visão abrangente sobre o processo de
                  criação de aplicativos móveis, desde a concepção inicial até a
                  disponibilização nas lojas de aplicativos. Durante duas horas,
                  exploraremos as etapas essenciais, incluindo a definição de
                  requisitos, design da interface do usuário, escolha da
                  plataforma e das tecnologias de desenvolvimento, práticas de
                  codificação, testes e depuração, além das melhores práticas
                  para publicação e manutenção contínua. O objetivo é ter uma
                  visão, fim a fim, de como projetar, desenvolver, entregar e
                  manter um aplicativo mobile..
                </p>
                <p className={styles.highlight}>
                  Local: LDC-5 (Instituto de Matemática e Computação)
                </p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>19:00 - Minicurso</div>
              </div>
              <div className={styles.descricao}>
                <b>Visualização de dados com ECharts</b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/gianluca-mendes-47a957234/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={gianluca}
                    alt="Fotografia do palestrante Gianluca"
                  />
                  <i>Gianluca Borges Mendes (UNIFEI)</i>
                </a>
                <a
                  href="https://www.linkedin.com/in/ysmaicon/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={ytalo}
                    alt="Fotografia do palestrante Ytalo"
                  />
                  <i>Ytalo Ysmaicon Gomes (UNIFEI)</i>
                </a>
                <p>
                  O minicurso "Visualização de Dados com ECharts" tem como
                  objetivo capacitar os participantes a criar visualizações de
                  dados interativas e dinâmicas utilizando a biblioteca ECharts.
                  Durante o encontro, os alunos aprenderão desde a configuração
                  do ambiente de desenvolvimento até a criação de gráficos
                  avançados e dinâmicos.
                </p>
                <p className={styles.highlight}>
                  Local: LDC-3 (Instituto de Matemática e Computação)
                </p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>20:00 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>O mundo (não) acessível dos jogos digitais</b>

               

                <a
                  target="_blank"
                  href="http://lattes.cnpq.br/3250170839435251"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={virginia}
                    alt="Fotografia do palestrante Virginia"
                  />
                  <i>Virgínia Fernandes Mota (COLTEC - UFMG)</i>
                </a>

                <a
                  target="_blank"
                  href="https://www.youtube.com/watch?v=XZ2FHmiiUP8"
                  className={styles.speakerInfo}
                >
                  <i>Live no Youtube</i>
                </a>
                <p>
                  Acessibilidade significa evitar barreiras desnecessárias que
                  impedem as pessoas de acessar ou aproveitar seu conteúdo. A
                  acessibilidade em jogos digitais é um desafio e é geralmente
                  subestimada. Esta apresentação visa demonstrar como é possível
                  criar jogos acessíveis para pessoas cegas ou com deficiência
                  visual severa, e para pessoas surdas ou com deficiência
                  auditiva severa. Usando a linguagem C# e o motor de jogos
                  Unity, esta palestra mostrará como é possível criar jogos
                  acessíveis de maneira simples e promover a conscientização
                  sobre acessibilidade.
                </p>
                <p className={styles.highlight}>Local: Online</p>
              </div>
            </div>
          </div>
        );
        break;
      case 3:
        return (
          <div className={styles.grid6}>
            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>14:00 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>Construindo sua Carreira no Game Design</b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/ikarosiqueira/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={ikaro}
                    alt="Fotografia do palestrante Ikaro"
                  />
                  <i>Ikaro Siqueira Rodrigues Vitor (Vancouver Film School)</i>
                </a>
                <p>
                  Palestra exclusiva para explorar a carreira em Game Design.
                  Iniciaremos com uma introdução abrangente sobre o que é Game
                  Design e os diferentes papéis profissionais na área. Em
                  seguida, abordaremos as habilidades técnicas, criativas e
                  interpessoais essenciais para o sucesso, destacando a
                  importância da educação formal e da autoeducação contínua.
                  Discutiremos como construir um portfólio diversificado e de
                  alta qualidade, crucial para se destacar no mercado. Além
                  disso, você terá uma visão clara sobre posições iniciais,
                  progressão de carreira e oportunidades no trabalho freelance e
                  no desenvolvimento indie. Também incluiremos insights sobre as
                  tendências atuais e futuras da indústria de jogos. Encerramos
                  com uma sessão de perguntas e respostas, proporcionando
                  recursos adicionais para seu aprendizado contínuo e
                  crescimento profissional.
                </p>
                <p className={styles.highlight}>Local: Auditório da Elétrica</p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>15:00 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>Da ciência à inovação, um processo de formação plena</b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/mauricio-de-pinho-bitencourt-b297712a/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={mauricio}
                    alt="Fotografia do palestrante Mauricio"
                  />
                  <i>Mauricio de Pinho Bitencourt (INOVAI)</i>
                </a>
                <p>
                  A abordagem permitirá ao público entender qual o caminho que o
                  pesquisador pode seguir, desde o inicio na graduação até se
                  tornar um protagonista de um projeto inovador. Incialmente
                  pelo resgaste dos processo histórico das transformações
                  (revoluções) tecnológicas, como o encadeamente Ciência,
                  Tecnologia e Inovação acontece, e por fim, como se preparar
                  durante a vida acadêmica e melhor se posicionar no mercado de
                  atuação.
                </p>
                <p className={styles.highlight}>Local: Auditório da Elétrica</p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>16:00 - Sessão de Poster</div>
              </div>
              <div className={styles.descricao}>
                <p className={styles.highlight}>
                  Local: Salão de Apoio da Reitoria
                </p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>19:00 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>
                  Scrum - Um método ágil para gestão de projetos de software
                </b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/bernardovcarvalho/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={bernardo}
                    alt="Fotografia do palestrante Bernardo"
                  />
                  <i>Bernardo Vasconcelos de Carvalho (B2ML)</i>
                </a>
                <p>
                  Scrum é uma estrutura ágil de colaboração em equipe comumente
                  usada no desenvolvimento de software e em outros setores.
                  Bernardo fez mestrado na área e usa o método com sucesso há
                  muitos anos em suas empresas.
                </p>
                <p className={styles.highlight}>Local: Salão de Apoio a Reitoria</p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>20:00 - Palestra</div>
              </div>
              <div className={styles.descricao}>
                <b>
                  SOLID em Ação: Melhoria Contínua no Desenvolvimento de
                  Software
                </b>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/jvmsg/"
                  className={styles.speakerInfo}
                >
                  <img
                    className={styles.speakerPhoto}
                    src={joao}
                    alt="Fotografia do palestrante João"
                  />
                  <i>João Víctor de Moraes Santos Gomes (DDMX)</i>
                </a>
                <p>
                  Nesta palestra, exploraremos a aplicação prática dos
                  princípios SOLID no desenvolvimento de software e como eles
                  podem transformar a eficiência e a qualidade dos projetos.
                  Abordaremos cada um dos princípios - Responsabilidade Única,
                  Aberto/Fechado, Substituição de Liskov, Segregação de
                  Interface e Inversão de Dependência - através de exemplos
                  práticos para facilitar o entendimento e aplicação de cada um
                  deles.
                </p>
                <p className={styles.highlight}>
                  Local: Salão de Apoio da Reitoria
                </p>
              </div>
            </div>

            <div className={styles.descricaoContainer}>
              <div className={styles.descricaoTitulo}>
                <img src={arrow} className={styles.arrow} />
                <div>21:00 - Encerramento</div>
              </div>
              <div className={styles.descricao}>
                <p className={styles.highlight}>
                  Local: Salão de Apoio da Reitoria
                </p>
              </div>
            </div>
          </div>
        );
        break;
    }
  };

  return (
    <div>
      <div className={styles.conteudoContainer}>{renderDescricao()}</div>
    </div>
  );
}
