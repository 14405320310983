import React, { useState } from "react";
import styles from "../styles/Programacao.module.css";
import DatasContainer from "./DatasContainer.jsx";
import ConteudoContainer from "./ConteudoContainer";
import Botao from "./Botao";
import agenda from "../images/calendario.png";
// <a href="https://www.flaticon.com/br/icones-gratis/calendario" title="calendário ícones">Calendário ícones criados por Freepik - Flaticon</a>

export default function Programacao() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <section id="programacao" className={styles.programacao}>
      <h2 className={styles.title}>Programação</h2>
      <div className={styles.programacaoContainer}>
        <div className={styles.dates}>
          <DatasContainer
            selectedIndex={selectedIndex}
            dataIndex={0}
            setSelectedIndex={setSelectedIndex}
          ></DatasContainer>
          <DatasContainer
            selectedIndex={selectedIndex}
            dataIndex={1}
            setSelectedIndex={setSelectedIndex}
          ></DatasContainer>
          <DatasContainer
            selectedIndex={selectedIndex}
            dataIndex={2}
            setSelectedIndex={setSelectedIndex}
          ></DatasContainer>
          <DatasContainer
            selectedIndex={selectedIndex}
            dataIndex={3}
            setSelectedIndex={setSelectedIndex}
          ></DatasContainer>
        </div>

        <div className={styles.content}>
          <ConteudoContainer selectedIndex={selectedIndex}></ConteudoContainer>
        </div>
      </div>
      <div className={styles.btn}>
        <Botao
          url={
            "https://docs.google.com/spreadsheets/d/1AwjR8zkfVvljD65dT4vgmDryU5OFZ48YTEfeMqbwSLc/edit?gid=0#gid=0"
          }
      
          texto={"Confira a agenda da SECOMP 2024 aqui!"}
          img={agenda}
        />
      </div>
    </section>
  );
}
