import React, { useState } from "react"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";

import api from "../../services/api.ts"
import closeIcon from "../../images/icons/close.svg"
import infoIcon from "../../images/icons/info.svg"
import styles from "./EventModal.module.css"
import EventModalMessage from "../EventModalMessage/index.tsx"

interface EventModalProps {
  activity: any,
  showModal: (state: boolean) => void
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Email Inválido")
    .required("Campo Obrigatório")
})

export default function EventModal({ activity, showModal }: EventModalProps) {
  const {
    register,
    handleSubmit,
    reset
  } = useForm({ resolver: yupResolver(schema) });
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(0)
  const [responseError, setResponseError] = useState("")

  const onSubmitHandler = async (data) => {
    setIsLoading(true);
    const submitData = {
      activityId: activity.id,
      userEmail: data.email,
    }

    try {
      if (handleCourseSwitch(activity.eventType)) {
        const response = await api.post("/user/subscribe", submitData);
        setResponseStatus(response.status);
        notifySuccess();
        reset();
      }
    } catch (error) {
      console.error("error submiting the form", error)
      setResponseStatus(error.response.status || 500)
      setResponseError(error.response.data.message || "Erro desconhecido")
      notifyError();
    } finally {
      setIsLoading(false)
    }
  }

  function handleCourseSwitch(isHackathon: string) {
    if (isHackathon === "HACKATHON") return true;
    else if (isHackathon === "MINI_COURSE") return window.confirm("Você só pode participar de um minicurso. Se você já está inscrito em outro minicurso, sua inscrição será substituída!");
    else return window.confirm("Você só pode participar de um minicurso. Se você já está inscrito em outro minicurso, sua inscrição será substituída!")
  }

  function handleCloseModal() {
    showModal(false)
  }

  function handleContainerClick(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
  }

  const notifySuccess = () => {
    toast.success('Inscrição realizada!', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      className: styles.toast
    });
  }

  const notifyError = () => {
    toast.error('Erro na inscrição', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      className: styles.toast
    });
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function formatTime(dateString) {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 3);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  return (
    <div
      className={styles.background}
      onClick={() => handleCloseModal()}
    >
      <div
        className={styles.modalContainer}
        onClick={(event) => handleContainerClick(event)}
      >
        {!responseStatus ?
          <>
            <button
              className={styles.closeBtn}
              onClick={() => handleCloseModal()}
            >
              <img src={closeIcon} alt="Ícone de fechamento" />
            </button>
            <h1 className={styles.title}>{activity.name}</h1>

            <div className={styles.descriptionContainer}>
              <h2>Descrição:</h2>
              <p className={styles.description}>{activity.description}</p>
            </div>

            <div className={styles.eventData}>
              <span>{`Data: ${formatDate(activity.startDate)}`}</span>
              <span>{`Início: ${formatTime(activity.startDate)}`}</span>
              <span>{`Fim: ${formatTime(activity.endDate)}`}</span>
              <span>{`Sala: ${activity.room}`}</span>
              <span className={activity.needLaptop ? styles.needLaptop : styles.hidden}>Você precisará levar notebook</span>
            </div>

            <div className={activity.participantsLimit - activity.participantsRegistered ? styles.slotsBadge : styles.slotsBadgeEmpty}>
              <p>{`${activity.participantsLimit - activity.participantsRegistered} vagas`}</p>
            </div>

            <h2 className={styles.formsTitle}>Inscreva-se</h2>

            <form className={styles.formContainer} onSubmit={handleSubmit(onSubmitHandler)}>
              <label className={styles.formLabel} htmlFor="confirmEmail">Confirme com seu email:</label>
              <input
                {...register("email")}
                className={styles.formInput}
                type="email"
                id="confirmEmail"
                placeholder="E-mail da inscrição"
              />

              <div className={styles.subscriptionWarning}>
                <img src={infoIcon} alt="Ìcone de aviso" />
                <p>Só é possível escolher 1 minicurso além do hackathon</p>
              </div>

              <div className={styles.buttonContainer}>
                <button
                  className={styles.cancelBtn}
                  onClick={() => handleCloseModal()}
                >
                  Cancelar
                </button>
                {isLoading ?
                  <button disabled={isLoading} className={styles.confirmBtn} type="submit">
                    <div className={styles.loader} />
                  </button>
                  :
                  <button className={styles.confirmBtn} type="submit">
                    Confirmar inscrição
                  </button>
                }
              </div>
            </form>
          </> : <EventModalMessage status={responseStatus} activity={activity} errorMessage={responseError} onClick={handleCloseModal} />}
      </div>
    </div>
  )
};
