import React, { useState, useEffect } from "react";
import styles from "../styles/DatasContainer.module.css";

export default function DatasContainer(props) {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (props.selectedIndex === props.dataIndex) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [props.selectedIndex]);

  let stylesClasses = `${styles.datasContainer}`;

  if (selected) {
    stylesClasses = `${styles.datasContainer} ${styles.dataSelecionada}`;
  } else {
    stylesClasses = `${styles.datasContainer}`;
  }

  switch (props.dataIndex) {
    case 0:
      stylesClasses = stylesClasses + ` ${styles.datasContainerInicio}`;
      break;
    case 3:
      stylesClasses = stylesClasses + ` ${styles.datasContainerFim}`;
      break;
    default:
      stylesClasses = stylesClasses + ` ${styles.datasContainerMeio}`;
      break;
  }

  const renderDatas = () => {
    switch (props.dataIndex) {
      case 0:
        return (
          <div>
            <div className={styles.dataTitulo}>
              <span className={styles.day}>Domingo</span> (01/09)
            </div>
          </div>
        );
        break;
      case 1:
        return (
          <div>
            <div className={styles.dataTitulo}>
              <span className={styles.day}>Segunda-feira</span> (02/09)
            </div>
          </div>
        );
        break;
      case 2:
        return (
          <div>
            <div className={styles.dataTitulo}>
              <span className={styles.day}>Terça-feira</span> (03/09)
            </div>
          </div>
        );
        break;
      case 3:
        return (
          <div>
            <div className={styles.dataTitulo}>
              <span className={styles.day}>Quarta-feira</span> (04/09)
            </div>
          </div>
        );
        break;
    }
  };

  return (
    <div
      onClick={() => props.setSelectedIndex(props.dataIndex)}
      className={stylesClasses}
    >
      {renderDatas()}
    </div>
  );
}
