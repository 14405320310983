import React, { useState } from "react"
import styles from "./AccordionItem.module.css"

import chevronDown from "../../images/icons/chevron-down.svg"
import chevronUp from "../../images/icons/chevron-up.svg"

interface AccordionItemProps {
  title: string,
  description: string,
}

export default function AccordionItem({ title, description }: AccordionItemProps) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  }

  return (
    <div className={styles.accordionItemContainer}>
      <div className={styles.titleContainer}>
        <img onClick={handleClick} src={open ? chevronUp : chevronDown} alt="Ícone de flecha que indica abertura do cartão de dúvida"/>
        <h1>{title}</h1>
      </div>

      <div className={open ? styles.contentContainerOpen : styles.contentContainerClose}>
        <p>{description}</p>
      </div>

      <div className={open ? "" : styles.divisor}/>
    </div>
  )
};
