import React from "react";

import code from "../../images/subscribe/code.png"
import styles from "./GeneralError.module.css"
import errorIcon from "../../images/icons/error-icon.svg"
import Footer from "../../components/Footer";

export default function GeneralError({status, message, log}) {
  return (
    <section className={styles.subscribeContainer}>
      <img className={styles.background} src={code} alt="Imagem de fundo exibindo uma linguagem de programação" />
      <div className={styles.content}>

        <img className={styles.errorImg} src={errorIcon} alt="Icone de erro" />

        <p className={styles.errorTitle}>{message}</p>
        <p className={styles.errorMessage}>Tente novamente mais tarde</p>
        <p className={styles.errorLog}>{status + " " + log}</p>
      </div>

      <Footer />
    </section>
  );
};
