import React from "react"

import ddmx from "../../images/sponsors/ddmx.svg"
import dac from "../../images/sponsors/DAC.svg"
import prontLife from "../../images/sponsors/prontLife.svg"
import questrade from "../../images/sponsors/Questrade.svg"
import fts from "../../images/sponsors/fts.png"
import fapepe from "../../images/sponsors/fapepe.webp"
import descubra from "../../images/sponsors/descubra.png"
import byron from "../../images/sponsors/byron.png"
import styles from "./Sponsors.module.css"

export default function Sponsors() {
  return (
    <div className={styles.sponsorsContainer} id="patrocinadores">
      <h1 className={styles.title}>Patrocinadores</h1>

      <div className={styles.sponsorsContent}>
        <section className={styles.diamondSection}>
          <h2 className={styles.diamondTitle}>Diamante</h2>
          <div className={styles.diamondContent}>
            <img src={ddmx} alt="Logotipo da DDMX" onClick={() => window.open("https://ddmx.com.br/", "_blank")}/>
            <img src={prontLife} alt="Logotipo da ProntLife" onClick={() => window.open("https://prontlife.com.br/", "_blank")} />
          </div>
        </section>
        <section className={styles.goldSection}>
          <h2 className={styles.goldTitle}>Ouro</h2>
          <div className={styles.goldContent}>
            <img src={dac} alt="Logotipo da DAC Engenharia" onClick={() => window.open("https://dacengenharia.com.br/", "_blank")} />
          </div>
        </section>
        <section className={styles.silverSection}>
          <h2 className={styles.silverTitle}>Prata</h2>
          <div className={styles.silverContent}>
            <img src={questrade} alt="Logotipo da Questrade" onClick={() => window.open("https://www.questrade.com/", "_blank")} />
            <img src={fts} alt="Logotipo da fundação Theodomiro Santiago" onClick={() => window.open("https://fts.org.br/", "_blank")} />
          </div>
        </section>
        <section className={styles.supporterSection}>
          <h2 className={styles.supporterTitle}>Apoiadores</h2>
          <div className={styles.supporterContent}>
            <img src={descubra} alt="Logotipo da Descubra soluções" onClick={() => window.open("https://descubrasolucoes.com.br//", "_blank")} />
            <img src={fapepe} alt="Logotipo da FAPEPE" onClick={() => window.open("https://fapepe.org.br/", "_blank")} />
            <img src={byron} alt="Logotipo da byron solutions" onClick={() => window.open("https://www.byronsolutions.com/", "_blank")} />
          </div>
        </section>
      </div>
    </div>
  )
};
