import React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import GeneralError from "../screens/GeneralError/index.tsx";
import ManyRequests from "../screens/ManyRequests/index.tsx";
import InternalError from "../screens/InternalError/index.tsx";

export default function RootBoundary() {
  const error = useRouteError();

  if(isRouteErrorResponse(error)) {
    switch(error.status) {
      case 429:
        return <ManyRequests />
      case 500:
        return <InternalError /> 
      default:
        return <GeneralError status={error.status} message={error.statusText} log={error.data}/>
    }
  }
};
