import React from "react"

import code from "../../images/subscribe/code.png"
import manyRequests from "../../images/icons/internal-error.svg"
import styles from "./InternalError.module.css"
import Footer from "../../components/Footer"

export default function InternalError() {
  return (
    <section className={styles.subscribeContainer}>
      <img className={styles.background} src={code} alt="Imagem de fundo exibindo uma linguagem de programação" />
      <div className={styles.content}>

        <img className={styles.errorImg} src={manyRequests} alt="Imagem que representa erro interno no servidor" />

        <p className={styles.errorMessage}>Tente novamente mais tarde.</p>
        <p className={styles.errorLog}>INTERNAL_SERVER_ERROR</p>
      </div>

      <Footer />
    </section>
  )
};
