import React from "react";
import styles from "./SubscribedMessage.module.css"

import errorIcon from "../../images/icons/error-icon.svg"
import emailSent from "../../images/subscribe/email-sent.svg"

interface SubscribedProps {
  status: number
  goBack: (closeThis: boolean) => void
}

export default function SubscribedMessage({ status, goBack }: SubscribedProps) {
  const handleGoBack = () => {
    goBack(true);
  }

  return (
    <div className={styles.content}>

      {status === 201 &&
        <div className={styles.successfulContainer}>
          <img src={emailSent} alt="Icone de email enviado" />
          <h2 className={styles.successfulTitle}>E-mail de confirmação enviado!</h2>
          <p className={styles.successfulText}>Um e-mail de confirmação foi enviado para caixa de entrada do e-mail cadastrado para efetivar sua inscrição no evento</p>
          <button className={styles.successfulBtn} onClick={handleGoBack}>Voltar</button>
        </div>
      }

      {status === 400 &&
        <div className={styles.successfulContainer}>
          <img className={styles.icon} src={errorIcon} alt="Icone de erro" />
          <h2 className={styles.errorTitle}>Erro de validação</h2>
          <p className={styles.successfulText}>
            Confira as informações inseridas e tente novamente.
          </p>
          <button className={styles.successfulBtn} onClick={handleGoBack}>Voltar</button>
        </div>
      }
      {status === 409 &&
        <div className={styles.successfulContainer}>
          <img className={styles.icon} src={errorIcon} alt="Icone de erro" />
          <h2 className={styles.errorTitle}>Usuário já cadastrado</h2>
          <p className={styles.successfulText}>
            Você já se cadastrou com este e-mail.
          </p>
          <button className={styles.successfulBtn} onClick={handleGoBack}>Voltar</button>
        </div>
      }
      {status === 429 &&
        <div className={styles.successfulContainer}>
          <img className={styles.icon} src={errorIcon} alt="Icone de erro" />
          <h2 className={styles.errorTitle}>Muitas requisições</h2>
          <p className={styles.successfulText}>
            Detectamos muitas requisições vindas do seu dispositivo. Tente novamente mais tarde.
          </p>
          <button className={styles.successfulBtn} onClick={handleGoBack}>Voltar</button>
        </div>
      }
      {status === 500 &&
        <div className={styles.successfulContainer}>
          <img className={styles.icon} src={errorIcon} alt="Icone de erro" />
          <h2 className={styles.errorTitle}>Erro interno do servidor</h2>
          <p className={styles.successfulText}>
            Tente novamente mais tarde.
          </p>
          <button className={styles.successfulBtn} onClick={handleGoBack}>Voltar</button>
        </div>
      }

      {status !== 201 && status !== 400 && status !== 409 && status !== 429 && status !== 500 && (
        <div className={styles.successfulContainer}>
          <img className={styles.icon} src={errorIcon} alt="Icone de erro" />
          <h2 className={styles.errorTitle}>Erro ao realizar inscrição</h2>
          <p className={styles.successfulText}>
            Ocorreu um erro inesperado. Tente novamente mais tarde.
          </p>
          <button className={styles.successfulBtn} onClick={handleGoBack}>
            Voltar
          </button>
        </div>
      )}
    </div>
  )
};
