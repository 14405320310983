import React, { useState } from "react"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import api from "../../services/api.ts"
import styles from "./SubscribeForm.module.css"
import errorIcon from "../../images/icons/error-icon.svg"
import infoIcon from "../../images/icons/info.svg"

interface SubscribeFormProps {
  callback: (status: number) => void
}

interface User {
  name: string,
  registration?: string,
  email: string,
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Campo Obrigatório"),
  registration: yup
    .string(),
  email: yup
    .string()
    .email("Email Inválido")
    .required("Campo Obrigatório")
})

export default function SubscribeForm({callback} : SubscribeFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({ resolver: yupResolver(schema) });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (userData: User) => {
    setIsLoading(true);
    const submitData = {
      name: userData.name,
      email: userData.email,
      ...(userData.registration ? { registration: userData.registration } : {})
    }

    try {
      const response = await api.post("/user", submitData);
      callback(response.status)
      reset();
    } catch (error) {
      console.error("error submiting the form", error)
      callback(error.response?.status || 500);
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className={styles.subscribeForm}>
      <h2>Inscrição</h2>

      <div className={styles.inputContainer}>
        <input
          {...register("name")}
          className={!errors.name ? styles.formInput : styles.formInputError}
          type="text"
          id="name"
          placeholder="Nome Completo"
        />
        {errors.name?.message &&
          <div className={styles.errorContainer}>
            <img className={styles.errorIcon} src={errorIcon} alt="" />
            <span className={styles.errorMessage}>{errors.name?.message}</span>
          </div>
        }
      </div>
      <div className={styles.inputContainer}>
        <input
          {...register("registration")}
          className={!errors.registration ? styles.formInput : styles.formInputError}
          type="number"
          id="registration"
          placeholder="Matrícula (opcional)"
        />
        {errors.registration?.message &&
          <div className={styles.errorContainer}>
            <img className={styles.errorIcon} src={errorIcon} alt="" />
            <span className={styles.errorMessage}>{errors.registration?.message}</span>
          </div>
        }
      </div>
      <div className={styles.inputContainer}>
        <input
          {...register("email")}
          className={!errors.email ? styles.formInput : styles.formInputError}
          type="email"
          id="email"
          placeholder="E-mail (institucional se tiver)"
        />
        {errors.email?.message &&
          <div className={styles.errorContainer}>
            <img className={styles.errorIcon} src={errorIcon} alt="" />
            <span className={styles.errorMessage}>{errors.email?.message}</span>
          </div>
        }
      </div>

      <div className={styles.warning}>
        <img src={infoIcon} alt="" />
        <p className={styles.warningText}>O número de matrícula<br/> não é obrigatório</p>
      </div>

      <button
        type="submit"
        className={styles.subscribeBtn}
        disabled={isLoading}
      >
        {isLoading ?
          <div className={styles.loader}></div>
          :
          "Inscrever-se"
        }
      </button>
    </form>
  )
};
