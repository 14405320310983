import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals.js";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Subscribe from "./screens/Subscribe/index.tsx";
import EmailConfirmed from "./screens/EmailConfirmed/index.tsx";
import EmailAlreadyConfirmed from "./screens/EmailAlreadyConfirmed/index.tsx";
import UserNotFound from "./screens/UserNotFound/index.tsx";
import "./variables.css"
import ManyRequests from "./screens/ManyRequests/index.tsx";
import InternalError from "./screens/InternalError/index.tsx";
import GeneralError from "./screens/GeneralError/index.tsx";
import RootBoundary from "./errorBoundaries/RootBoundary.tsx";

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <RootBoundary />
  },
  {
    path: '/subscribe',
    element: <Subscribe />
  },
  {
    path: '/email-confirmed',
    element: <EmailConfirmed />
  },
  {
    path: '/already-confirmed',
    element: <EmailAlreadyConfirmed />
  },
  {
    path: '/user-not-found',
    element: <UserNotFound />
  },
  {
    path: '/too-many-requests',
    element: <ManyRequests />
  },
  {
    path: '/internal-error',
    element: <InternalError />
  },
  {
    path: '/error',
    element: <GeneralError />
  },
])

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
