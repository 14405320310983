import React, { useEffect, useState } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";

import code from "../../images/subscribe/code.png"
import logoHorizontal from "../../images/logo/horizontal-vectorized.svg"
import logoVertical from "../../images/logo/vertical.svg"
import SubscribedMessage from "../../components/SubscribedMessage/index.tsx"
import SubscribeForm from "../../components/SusbcribeForm/index.tsx"
import Institutions from "../../components/Institutions/index.tsx";
import Reminder from "../../components/Reminder/index.tsx"

import styles from "./Subscribe.module.css"
import api from "../../services/api.ts"
import Footer from "../../components/Footer.jsx"
import EventModal from "../../components/EventModal/index.tsx"
import Events from "../../components/Events/index.tsx";
import { Link } from "react-router-dom";

interface EventProps {
  name: string,
  description: string,
  participantsLimit: number,
  participantsRegistered: number,
  startDate: string,
  endDate: string,
  needLaptop: boolean
}

export default function Subscribe() {
  const [responseStatus, setResponseStatus] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<EventProps>({
    name: "Atividade",
    description: "description",
    participantsLimit: 20,
    participantsRegistered: 0,
    startDate: "",
    endDate: "",
    needLaptop: false,
  });
  const [activities, setActivities] = useState<EventProps[]>([]);

  useEffect(() => {
    async function fetchActivities() {
      try {
        const response = await api.get("/activity/get-all-activities");
        setActivities(response.data.data)
      } catch (error) {
        console.error(error)
      }
    }

    fetchActivities()
  }, [showModal])

  const handleCloseMessage = (closeThis: boolean) => {
    if (closeThis)
      setResponseStatus(0);
  }


  return (
    <main className={styles.subscribeContainer}>
      <img className={styles.background} src={code} alt="Imagem de fundo exibindo uma linguagem de programação" />

      <section className={styles.formSection}>
        <Link to="/"><img className={styles.logoHorizontal} src={logoHorizontal} alt="Logotipo da Semana da Computação" /></Link>
        
        <Link to="/"><img className={styles.logoVertical} src={logoVertical} alt="Logotipo da Semana da Computação" /></Link>

        {!responseStatus ?
          <SubscribeForm callback={setResponseStatus} />
          :
          <SubscribedMessage status={responseStatus} goBack={handleCloseMessage} />
        }
      </section>

      <Reminder />

      
      <Events activities={activities} setSelectedActivity={setSelectedActivity} setShowModal={setShowModal} />
      

      <div className={styles.sponsorsContainer}>
        <Institutions />
      </div>

      <Footer />

      {showModal &&
        <EventModal activity={selectedActivity} showModal={setShowModal} />
      }

      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        closeButton={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        className={styles.toastContainer}
      />
    </main>
  )
};
