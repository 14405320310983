import React, { useState, useEffect } from "react";
import styles from "../styles/DatasContainerOrg.module.css";

export default function DatasContainerOrg(props) {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (props.selectedIndex === props.dataIndex) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [props.selectedIndex]);

  let stylesClasses = `${styles.data}`;
  if (selected) {
    stylesClasses = `${styles.data} ${styles.dataSelecionada}`;
  } else {
    stylesClasses = `${styles.data}`;
  }

  const renderData = () => {
    switch (props.dataIndex) {
      case 0:
        return <div className={styles.texto}>01/09</div>;
        break;
      case 1:
        return <div className={styles.texto}>02/09</div>;
        break;
      case 2:
        return <div className={styles.texto}>03/09</div>;
        break;
      case 3:
        return <div className={styles.texto}>04/09</div>;
        break;
    }
  };

  return (
    <div
      onClick={() => props.setSelectedIndex(props.dataIndex)}
      className={stylesClasses}
    >
      {renderData()}
    </div>
  );
}
