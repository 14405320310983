import React from "react"
import styles from "./FAQ.module.css"
import Accordion from "../Accordion/index.tsx"
import { Link } from "react-scroll";
export default function FAQ() {
  return (
    <section className={styles.faqContainer}>
      <h1 className={styles.title}>Dúvidas Frequentes</h1>

      <Accordion questions={questions} />
    </section>
  )
};

const questions = [
  {
    title: "O que é a SECOMP?",
    description: `É a Semana da Computação, evento organizado pelos Institutos de Matemática e Computação (IMC) e de Engenharia de Sistemas e Tecnologias da Informação (IESTI) da Universidade Federal de Itajubá, com o auxílio do Diretório Acadêmico de Computação (DACOMP).
    Este evento é capaz de proporcionar a comunidade diversas experiências e aprendizados na área da computação, compartilhando conhecimento através de palestras, hackathon e minicursos.`
  },
  {
    title: "Como me inscrever no evento e atividades?",
    description: (
      <>
        Clicando <a href="/">aqui</a> ou no botão no início da página você será redirecionado para a tela de inscrição no evento.
        No primeiro formulário de inscrição você estará inscrito para assistir à todas as palestras do evento, no entanto ainda não estará inscrito nas demais atividades do evento, as quais você poderá se inscrever através dos cartões ao longo da página de inscrição.
        Será possível a inscrição, além do hackathon (opcional) em apenas 1 (UM) único minicurso.
      </>
    )
  },
  {
    title: "Como funcionam as vagas?",
    description: `As atividades do evento, sejam elas o hackathon ou qualquer um dos minicursos, possuem vagas limitadas para inscrição devido a disponibilidade de salas reservadas no IMC.
    As vagas disponíveis para cada atividade são exibidas nos seus respectivos cartões na página de inscrição e são ocupadas por ordem de inscrição, portanto se inscreva já!`
  },
  {
    title: "Onde acontecerão as atividades? E como conseguirei acessá-las no dia?",
    description: <>
    Os minicursos e hackathon acontecerão no IMC (Instituto de Matemática e Computação), porém para as outras atividades do evento é preciso verificar no cronograma da página então fique atento em que sala ocorrerá cada atividade, clique <Link activeClass="active" to="programacao" spy={true} smooth={true}>
            <a className={styles.underline}>aqui</a>
          </Link> para ver essas informações. Será enviado no e-mail informado na inscrição do evento um ingresso com o QR Code de acesso que deverá ser apresentado aos organizadores do evento na entrada do local de cada atividade para garantir o acesso e validação da sua presença.
    </>
  },
  {
    title: "Como me inscrever no evento no SIGAA Unifei para registro de horas?",
    description: (
      // need to update the pdf link
    <>
      Seguindo o tutorial feito <a href="https://drive.google.com/file/d/1tHIh04usNQFMzbM5tOYJd01qtEkThFeK/view?usp=sharing">neste pdf</a> você conseguirá garantir o seu certificado de participação.
    </>)
  },
]
