import React from "react";

import arrowIcon from "../../images/icons/double-arrow-down.svg"
import styles from "./Reminder.module.css"

export default function Reminder() {
  return (
    <section className={styles.reminder}>
      <img className={styles.arrow} src={arrowIcon} alt="Ícone com setas apontando para baixo" />
      <p className={styles.reminderTextSigaa}>
        SECOMP 2024
      </p>
      <p className={styles.reminderTextDescription}>
      Essa inscrição lhe dará acesso as palestras do evento e demais atividades como: Feira de Extensão, Rodadas de Estágio, Mesa Redonda e Sessão de Pôsteres. Para participar dos minicursos e o Hackathon precisa de uma inscrição a parte.

      </p>
      <p className={styles.reminderTextComeback}>
        Para você aluno de graduação garantir seu certificado de participação no evento SECOMP, se inscreva também no SIGAA pelo botão abaixo
      </p>
      <button className={styles.sigaaBtn}>
        <a 
          target="_blank"
          href="https://sigaa.unifei.edu.br/sigaa/public/extensao/loginCursosEventosExtensao.jsf"
        >
          Acessar SIGAA
        </a>
      </button>
      <a
        className={styles.sigaaTutorial}
        href="https://drive.google.com/file/d/1tHIh04usNQFMzbM5tOYJd01qtEkThFeK/view?usp=sharing"
        target="_blank"
      >
        Como me inscrever pelo SIGAA?
      </a>

    </section>
  )
};
