import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/Botao.module.css";

export default function Botao(props) {
  const navigate = useNavigate();
  const externLink = () => {
    window.open(props.url, "_blank");
  };

  return (
    <button
      className={styles.btn}
      onClick={() => {
        props.internLink ? navigate(props.url) : externLink();
      }}
    >
      <img src={props.img} alt="mão clicando" className={styles.btnImg} />
      <span className={styles.btnText}>{props.texto}</span>
    </button>
  );
}
