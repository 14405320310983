import React from "react"
import AccordionItem from "../AccordionItem/index.tsx"
import styles from "./Accordion.module.css"

export default function Accordion({questions}) {

  return (
    <div className={styles.accordionContainer}>
      {questions.map((question, index) =>
        <AccordionItem key={index} {...question} />
      )}
    </div>
  )
};
