import React, { useState } from "react";
import styles from "../styles/Navbar.module.css";
import logo from "../images/logo/horizontal.png";
import hamburgerIcon from "../images/icons/hamburger.svg"
import "../fonts/Assistant/static/Assistant-Medium.ttf";
import { Link } from "react-scroll";

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className={styles.navbarContainer}>
      <header className={styles.navbar}>
        <Link activeClass="active" to="hero" spy={true} smooth={true}>
          <div className={styles.navLogo}>
            <a href="#hero" className={styles.link}>
              <img src={logo} alt="Logo da SECOMP" className={styles.logo} />
            </a>
          </div>
        </Link>

        <ul className={styles.navMenu}>
          <a
            target="_blank"
            className={styles.link}
            activeClass="active"
            href="https://docs.google.com/forms/d/e/1FAIpQLSepCjbPA6yGuiyr9fadvyDjC8KC1Qwc7jCZZtFdEIIYH02Hrg/viewform?usp=pp_url"
            spy={true}
            smooth={true}
            rel="noreferrer"
          >
            <span className={styles.navItem}>Chamada de Pôsteres</span>
          </a>

          <Link activeClass="active" to="sobre" spy={true} smooth={true}>
            <span className={styles.navItem}>Sobre</span>
          </Link>

          <Link activeClass="active" to="programacao" spy={true} smooth={true}>
            <span className={styles.navItem}>Programação</span>
          </Link>

          <Link
            activeClass="active"
            to="organizadores"
            spy={true}
            smooth={true}
          >
            <span className={styles.navItem}>Palestrantes</span>
          </Link>

          <Link
            activeClass="active"
            to="patrocinadores"
            spy={true}
            smooth={true}
          >
            <span className={styles.navItem}>Patrocinadores</span>
          </Link>
        </ul>

        <button onClick={toggleMenu} className={styles.burgerBtn}>
          <img
            src={hamburgerIcon}
            alt="Icone para abri o menu mobile"
          />
        </button>
      </header>

      <div className={showMenu ? styles.showMenu : styles.hideMenu}>
        <ul className={styles.verticalMenu}>
          <a
            target="_blank"
            className={styles.menuItem}
            activeClass="active"
            href="https://docs.google.com/forms/d/e/1FAIpQLSepCjbPA6yGuiyr9fadvyDjC8KC1Qwc7jCZZtFdEIIYH02Hrg/viewform?usp=pp_url"
            spy={true}
            smooth={true}
            rel="noreferrer"
          >
            <span className={styles.menuText}>Chamada de Pôsteres</span>
          </a>
          <Link activeClass="active" to="sobre" spy={true} smooth={true} className={styles.menuItem}>
            <span className={styles.menuText}>
              Sobre
            </span>
          </Link>
          <Link activeClass="active" to="programacao" spy={true} smooth={true} className={styles.menuItem}>
            <span className={styles.menuText}>
              Programação
            </span>
          </Link>
          <Link activeClass="active" to="organizadores" spy={true} smooth={true} className={styles.menuItem}>
            <span className={styles.menuText} href="#social">
              Palestrantes
            </span>
          </Link>
          <Link activeClass="active" to="patrocinadores" spy={true} smooth={true} className={styles.menuItem}>
            <span className={styles.menuText} href="#photos">
              Patrocinadores
            </span>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
