import styles from './App.css';
import React from "react";
import Navbar from './components/Navbar.jsx';
import Hero from './components/Hero';
import Programacao from './components/Programacao';
import Sobre from './components/Sobre';
import Localizacao from './components/Localizacao';
import Footer from './components/Footer';
import Organizadores from './components/Organizadores';
import OrganizadoresLista from './components/OrganizadoresLista';
import FAQ from './components/FAQ/index.tsx';
import Institutions from './components/Institutions/index.tsx';
import Sponsors from './components/Sponsors/index.tsx';

function App() {

  return (
    <div className={styles.App}>
      <Navbar/>
      <Hero/>
      <Sobre/>
      <Programacao/>      
      <Organizadores/>
      <Sponsors />
      <FAQ />
      <Localizacao/>
      <OrganizadoresLista/>
      <Institutions />
      <Footer/>
    </div>
  );
}

export default App;
