import React from "react"
import styles from "./EmailAlreadyConfirmed.module.css"

import code from "../../images/subscribe/code.png"
import logoHorizontal from "../../images/logo/horizontal-vectorized.svg"
import logoVertical from "../../images/logo/vertical.svg"
import emailConfirmed from "../../images/subscribe/email-checked.svg"
import { useNavigate } from "react-router-dom"
import Institutions from "../../components/Institutions/index.tsx"
import Footer from "../../components/Footer.jsx"

export default function EmailAlreadyConfirmed() {
  const navigate = useNavigate()

  return (
    <section className={styles.subscribeContainer}>
      <img className={styles.background} src={code} alt="Imagem de fundo exibindo uma linguagem de programação" />

      <div className={styles.content}>
        <img className={styles.logoHorizontal} src={logoHorizontal} alt="Logotipo da Semana da Computação" />
        <img className={styles.logoVertical} src={logoVertical} alt="Logotipo da Semana da Computação" />

        <div className={styles.successfulContainer}>
          <img src={emailConfirmed} alt="Icone de email confirmado" />
          <h2 className={styles.successfulTitle}>E-mail já confirmado!</h2>
          <p className={styles.successfulText}>E-mail confirmado e inscrição realizada.<br /> Seu ingresso com QRCode de acesso foi enviado ao e-mail cadastrado</p>
          <button className={styles.successfulBtn} onClick={() => navigate("/subscribe")}>Voltar</button>
        </div>
      </div>

      <Institutions />

      <Footer />
    </section>
  )
};
