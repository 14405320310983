import React from "react";
import styles from "../styles/ConteudoContainerOrg.module.css";

export default function ConteudoContainerOrg(props) {
  const renderSlider = () => {
    switch (props.selectedIndex) {
      case 1:
        return (
          <div className={styles.grid6}>
            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>
                  Luiz Felipe Fernandes Machado Costa
                </div>
                <div className={styles.participanteConteudo}>
                  Engenheiro de Software na Red Hat, a maior empresa
                  especializada em Linux do mundo. Construiu uma carreira na
                  área de consultoria, criando soluções e dando treinamentos em
                  infraestrutura e automação para grandes empresas. Hoje,
                  trabalha no Ansible, uma das principais ferramentas de
                  automação. Também possui uma longa experiência com comunidades
                  open-source e cybersegurança.
                </div>
              </div>
            </div>

            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>
                  Guilherme Augusto Ferreira
                </div>
                <div className={styles.participanteConteudo}>
                  Desenvolvedor Full Stack com mais de 8 anos de experiência,
                  impulsionando estratégias de implementação, entregando
                  componentes funcionais e orientando equipes na criação de
                  soluções de software robustas. Defensor de código limpo e
                  lógico, com expertise em arquiteturas de microsserviços e
                  tecnologias como Docker, GitHub Actions, Azure e AWS.
                  Proficiente em .Net Core, NodeJS, Angular, React e
                  desenvolvimento híbrido, além de bancos de dados SQL e NoSQL.
                  Líder nato, motivando equipes e comunicando-se efetivamente
                  com stakeholders.
                </div>
              </div>
            </div>

            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>
                  Renata Mendes Araújo
                </div>
                <div className={styles.participanteConteudo}>
                  Professora na Faculdade de Computação e Informática e
                  pesquisadora do Programa de Pós-Graduação em Computação
                  Aplicada da Universidade Presbiteriana Mackenzie. Pesquisadora
                  do Programa de Pós-Graduação em Sistemas de Informação da
                  EACH-USP e do Programa de Doutorado Profissional em Políticas
                  Públicas da ENAP. Pesquisadora convidada do LUDES-Laboratório
                  de Ludologia, Engenharia e Simulação da COPPE/UFRJ.
                  Representante de Inovação e Empreendedorismo da Faculdade de
                  Computação e Informática na Coordenadoria de Desenvolvimento e
                  Inovação da Universidade Presbiteriana Mackenzie (2019-2023).
                  Possui graduação em Informática pela Universidade Federal do
                  Rio de Janeiro (1992), mestrado (1994) e doutorado (2000) em
                  Engenharia de Sistemas e Computação pela Universidade Federal
                  do Rio de Janeiro. Tem experiência de pesquisa,
                  desenvolvimento tecnológico e inovação na área de Computação,
                  com ênfase em Sistemas de Informação, atuando principalmente
                  nos seguintes temas: governo e democracia digital, gestão da
                  inovação, gestão de processos de negócio e sistemas
                  colaborativos. Coordena o Grupo de Pesquisa e Inovação em
                  Ciberdemocracia (CIBERDEM)(ciberdemack.com.br). Tem atuado
                  continuamente na consolidação da comunidade de ensino e
                  pesquisa em Sistemas de Informação no Brasil, em particular
                  junto à Sociedade Brasileira de Computação (SBC). Ocupou a
                  Diretoria de Educação da Sociedade Brasileira de Computação
                  (2017-2019)
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case 2:
        return (
          <div className={styles.grid6}>
            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>Wendel Nascimento</div>
                <div className={styles.participanteConteudo}>
                  Desenvolvedor há mais de 10 anos, comecei minha jornada
                  criando servidores privados de GunBound, de fato aprendi o que
                  significava programação em um curso técnico e desde então
                  nunca mais parei. Já passei por empresas das mais diversas
                  indústrias como mídia, educação e varejo. Fundei minha própria
                  empresa e hoje trabalho no time de engenharia da Uber.
                </div>
              </div>
            </div>

            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>Ana Raquel Calhau</div>
                <div className={styles.participanteConteudo}>
                  Co-fundadora e CEO da NexAtlas | Growth Strategist | TEDx
                  Speaker Trabalha diretamente com startups desde 2012, tendo
                  sido mentora de criação de novos negócios, elaboração de
                  pitchs e growth hacking para mais de 150 startups em 2 países.
                  Através da NexAtlas, participou do programa brasileiro de
                  aceleração de startups SEED, conquistou o 3º lugar no maior
                  programa de aceleração da América Latina (Start-Up Chile),
                  lançou um produto com o apoio do Samsung Creative Startups e
                  ficou no Top 10 startups traveltechs do 100 Open Startups. Com
                  a Laticin foi selecionada entre as 10 finalistas de mais de
                  800 projetos do desafio global Thought For Food; graduou no
                  Startup School da Y Combinator e foi uma das 50 selecionadas
                  para o programa de aceleração FIEMG Lab 4.0. Finalista da
                  categoria Rising Stars do Globant Awards, eleita Founder do
                  Ano no prêmio Vortex 2022, finalista dos prêmios Mulheres
                  Inovadoras da FINEP e Women in Tech Latam, além de fellow do
                  programa Young Leaders of the Americas Initiative (YLAI).
                  Graduada em Sistemas da Informação com ênfase em
                  Empreendedorismo pela Universidade Federal de Itajubá, também
                  é certificada no Venture Deals da Kauffman Fellows, em
                  Building and Managing Resilient Teams da Universidade de
                  Chicago, pelo MBA Essentials da London School of Economics and
                  Political Science e em Gestão Executiva pela University of
                  Akron.
                </div>
              </div>
            </div>

            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>Flavia Rangel</div>
                <div className={styles.participanteConteudo}>
                  Meu nome é Flávia Rangel. Me formei no ano de 2014 pela UNIFEI
                  no curso de Ciência da Computação. Durante minha graduação
                  tive a oportunidade de trabalhar nos laboratórios da
                  universidade, de fazer estágio nas empresas encubadas no
                  campus, de participar da maratona de programação e de ganhar
                  uma bolsa de estudos pelo programa Ciência Sem Fronteiras, o
                  qual me deu a oportunidade única de morar e estudar por um
                  período nos Estados Unidos. Após formada, meu primeiro emprego
                  foi na empresa PagSeguro como desenvolvedora backend, onde eu
                  aprendi a lidar com o ambiente corporativo e encontrar novas
                  formas de aprendizado. Em 2018, graças a minha experiência no
                  Pagseguro, meus bons fundamentos de algoritmos da faculdade e
                  minha habilidade de falar inglês, eu passei na entrevista da
                  Uber como Desenvolvedora Backend "Pleno". E por fim hoje,
                  assumo o cargo de Gerente de Engenharia em um time que
                  desenvolve serviços de verificação de identidade na plataforma
                  da Uber.
                </div>
              </div>
            </div>

            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>
                  Rodrigo Franco Toso
                </div>
                <div className={styles.participanteConteudo}>
                  Rodrigo é um cientista e lidera o time de Otimização de
                  Receita no Microsoft Bing. Ele ingressou na Microsoft em 2017.
                  Recebeu seu B.Sc. em Ciência da Computação pela Universidade
                  Federal de Lavras em Lavras, MG, Brasil, e seu M.Sc. em
                  Ciência da Computação pela Universidade Federal Fluminense em
                  Niterói, RJ, Brasil. Em seguida, mudou-se para os EUA para
                  estudar na Rutgers University (Ph.D., Ciência da Computação |
                  Aprendizado de Máquina e Otimização) em Piscataway, NJ. Em seu
                  tempo livre, ele aproveita a vida com sua família e escala
                  geleiras e vulcões no Estado de Washington, EUA.
                </div>
              </div>
            </div>

            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>
                  Virgínia Fernandes Mota
                </div>
                <div className={styles.participanteConteudo}>
                  Professora do curso de Desenvolvimento de Sistemas do
                  COLTEC-Universidade Federal de Minas Gerais (UFMG). Possui
                  doutorado em Ciência da Computação pela UFMG, mestrado em
                  Modelagem Computacional pela Universidade Federal de Juiz de
                  Fora, e mestrado em Systèmes Intelligents et Communicants pela
                  École Nationale Supérieure de l'Électronique et de ses
                  Applications (ENSEA/France). Também possui bacharelado em
                  Ciência da Computação pela Universidade Federal de Juiz de
                  Fora. Ela supervisionou trabalhos que venceram o Technovation
                  Challenge (Mappid em 2020), Change the Game (2ª Edição) e
                  Gamethons (2023).Professora do curso de Desenvolvimento de
                  Sistemas do COLTEC-Universidade Federal de Minas Gerais
                  (UFMG). Possui doutorado em Ciência da Computação pela UFMG,
                  mestrado em Modelagem Computacional pela Universidade Federal
                  de Juiz de Fora, e mestrado em Systèmes Intelligents et
                  Communicants pela École Nationale Supérieure de l'Électronique
                  et de ses Applications (ENSEA/France). Também possui
                  bacharelado em Ciência da Computação pela Universidade Federal
                  de Juiz de Fora. Ela supervisionou trabalhos que venceram o
                  Technovation Challenge (Mappid em 2020), Change the Game (2ª
                  Edição) e Gamethons (2023).
                </div>
              </div>
            </div>

            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>
                  Gianluca Borges Mendes e Ytalo Ysmaicon Gomes
                </div>
                <div className={styles.participanteConteudo}>
                  Ytalo é bacharel em Engenharia de Computação e atualmente
                  cursa mestrado em Ciência e Tecnologia da Computação, ambos
                  pela Universidade Federal de Itajubá (UNIFEI). Possui
                  experiência na área de dados, atuando em projetos de
                  processamento e visualização de informação no contexto de
                  cidades inteligentes. Gianluca é graduando em Sistemas de
                  Informação na Universidade Federal de Itajubá (UNIFEI). Possui
                  experiência em projetos de visualização de dados no contexto
                  de monitoramento de marca.
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case 3:
        return (
          <div className={styles.grid6}>
            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>
                  João Víctor de Moraes Santos Gomes
                </div>
                <div className={styles.participanteConteudo}>
                  Sou engenheiro da computação, graduado pela Universidade
                  Federal de Itajubá (UNIFEI) em 2022. Durante a graduação,
                  participei do projeto de extensão Blackbee Drones, atuando na
                  área de desenvolvimento de software, e realizei pesquisas de
                  iniciação científica no laboratório Sinapse, focadas em
                  aprendizado de máquina, com patrocínio da empresa DDMX. No
                  final da minha iniciação científica, iniciei meu estágio na
                  DDMX como estagiário em análise de dados, aplicando os
                  resultados das pesquisas em produtos que impactaram a operação
                  diária de empresas do setor de distribuição de energia. Desde
                  então, tenho trabalhado na DDMX. Atualmente, coordeno projetos
                  realizados na plataforma interna da empresa, atendendo
                  clientes dos setores de coleta de lixo, mineração, metalurgia
                  e distribuição de energia.
                </div>
              </div>
            </div>

            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>
                  Ikaro Siqueira Rodrigues Vitor
                </div>
                <div className={styles.participanteConteudo}>
                  Ikaro Siqueira é um Game Designer com experiencia
                  desenvolvendo jogos a mais de 5 anos. Se formou em Ciência da
                  Computação pela UNIFEI em 2022. Em 2023, formou-se com honras
                  em Game Design pela prestigiada Vancouver Film School, onde
                  foi reconhecido como o aluno mais premiado do ano. Produzindo
                  jogos em escala global como Producer & Lead Designer, Ikaro
                  continua a expandir os limites da indústria.
                </div>
              </div>
            </div>

            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>
                  Mauricio de Pinho Bitencourt
                </div>
                <div className={styles.participanteConteudo}>
                  Possui graduação em Engenharia Elétrica com ênfase em
                  Telecomunicações, MBA, e especialização em finanças. Como
                  engenheiro participou de projetos e implantação de sistemas de
                  comunicações móveis, (celular) em empresas como VIVO, Oi e
                  Motorola na cidade de São Paulo. Como consultor de negócios,
                  foi gerente de marketing na Nextel no desenvolvimento de
                  produtos; atuou como gerente nas incubadoras de empresas de
                  base tecnológica do INATEL e INCIT, premiadas nacionalmente;
                  mentor e corresponsável pelo nascimento e crescimento de mais
                  de 100 empresas de base tecnológica ao longo dos últimos 15
                  anos; ministra cursos nas áreas de marketing, finanças e
                  empreendedorismo, em graduação e pós graduação; consultor da
                  empresa Valor Consultoria Empresarial, onde atua na elaboração
                  de modelos de negócios, estratégias e planejamento
                  estratégico. Consultoria para formatação de plano de
                  investimentos, valuation e elaboração de projetos de P&D&I
                  para captação de apoio financeiro. Ocupou o cargo de Vice
                  Presidente da Rede Mineira de Inovação, RMI, e atualmente é
                  Diretor Geral da INOVAI, Associação Itajubense de Inovação e
                  Empreendedorismo.
                </div>
              </div>
            </div>

            <div className={styles.externo}>
              <div className={styles.interno}>
                <div className={styles.participanteNome}>
                  Bernardo Vasconcelos de Carvalho
                </div>
                <div className={styles.participanteConteudo}>
                  Bernardo é presidente da Fundação Theodomiro Santiago. É
                  mestre e engenheiro da computação pela UNIFEI. CEO da B2ML
                  Sistemas e programador há mais de 25 anos.
                </div>
              </div>
            </div>
          </div>
        );
        break;
    }
  };
  return <div>{renderSlider()}</div>;
}
