import React from "react";
import styles from "../styles/Sobre.module.css";
import apresentacao from "../images/apresentacao.png";
import ong from "../images/ong.png";

function Sobre() {
  return (
    <section id="sobre" className={styles.sobre}>
      <div className={styles.content}>
        <img
          src={apresentacao}
          alt="Homem ensinando para uma sala cheia de pessoas"
          className={styles.image}
        />
        <div className={styles.texts}>
          <div className={styles.linha}>
            <h2 className={styles.title}>Sobre a SECOMP</h2>
          </div>
          <p className={styles.subtitle}>Faça parte dessa evolução!</p>
          <p className={styles.text}>
            {`Venha passar quatro dias mergulhado no mundo da Computação, trocando ideias com a galera mais antenada, descobrindo coisas novas e ainda se conectando com profissionais e empresas que estão fazendo a diferença. Essa é a vibe da SECOMP, nossa Semana da Computação da UNIFEI, campus Itajubá!\n\n`}
            {`O evento visa proporcionar para estudantes, professores e público de TI da região uma imersão em atividades de tecnologia. E o melhor de tudo: o evento traz instituições de educação e empresas para o mesmo espaço, criando um ambiente perfeito para quem quer se conectar e colaborar com o futuro da tecnologia. Não dá pra ficar de fora, né?\n`}
          </p>
        </div>

        <div className={styles.subContentBG}>
          <div className={styles.subContent}>
            <div className={styles.history}>
              <div className={styles.linha}>
                <h2 className={styles.title}>Histórico</h2>
              </div>
              <p className={styles.text}>
                {`Ao longo dos anos, a semana acadêmica de computação da UNIFEI, campus Itajubá teve diversos nomes e formatos, como Composium, SEPROG e SECOMP. Entendemos que SECOMP é mais abrangente e democrático e, por isso, pretendemos consolidar o nome do evento como SECOMP.\n\n`}
                {`Participar da SECOMP é uma oportunidade única para quem quer se atualizar, aprender com os melhores e, claro, fazer aquele network que pode abrir muitas portas. Durante a semana, você vai curtir palestras inspiradoras, debates, minicursos práticos, e ainda vai poder conferir de perto projetos científicos, trabalhos de extensão e muito mais.\n`}
              </p>
            </div>

            <div className={styles.event}>
              <div className={styles.linha}>
                <h2 className={styles.title}>O evento</h2>
              </div>
              <p className={styles.text}>
                {`O evento contará com diferentes instituições participantes, tanto educacionais quanto corporativas, potencializando a formação de redes colaborativas entre essas instituições.\n\n`}
                {`Concurso de Logotipos:\nUm dos sub-eventos da II SECOMP será o concurso de Logotipos! Seu propósito é eleger uma identidade visual nova e atual para os cursos de computação da UNIFEI - Campus Itajubá! Os vencedores do concurso serão apresentados na abertura do evento e contamos com toda comunidade “Unifeiana” na votação para eleger essa identidade visual.\n`}
              </p>
            </div>

            <div className={styles.ong}>
              <div className={styles.linha}>
                <h2 className={styles.title}>Responsabilidade Social</h2>
              </div>
              <div className={styles.description}>
                <img src={ong} alt="Logotipo da ONG Projeto Alimente" />
                <p className={styles.text}>
                  A SECOMP também tá ligada nas boas causas! Queremos fazer a
                  diferença junto com vocês e apoiar a ONG PROJETO ALIMENTE.
                  Então, bora contribuir? Traga 1 litro de leite ou alimentos
                  não perecíveis e ajude quem precisa. Juntos, podemos fazer
                  muito mais!
                </p>
              </div>
              <a
                href="https://www.instagram.com/ong_projetoalimente/"
                target="_blank"
              >
                Acesse o instagram
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sobre;
