import React from "react";
import EventCard from "../EventCard/index.tsx";

import styles from "./Events.module.css"
import { toast } from "react-toastify";

export default function Events({ activities, setSelectedActivity, setShowModal }) {
  function compareDates(dateReceived: string) {
    const dateObject = new Date(dateReceived)
    const currentDate = new Date()
    return !(dateObject < currentDate) // if the event time has already passed, set available as false
  }

  const notifyAvailable = () => {
    toast.error('Atividade Indisponível', {
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      className: styles.toast
    });
  }

  return (
    <>
     
      <p className={styles.reminderTextSubscribe}>Se inscreva também para as outras atividades a seguir!</p>
      <div className={styles.events}>
        <div className={styles.cardsContainer}>
          {activities.map((activity, index) =>
            <EventCard
              key={index}
              title={activity.name}
              description={activity.description}
              slots={activity.participantsLimit - activity.participantsRegistered}
              available={compareDates(activity.endDate)}
              onClick={() => {
                if (activity.participantsLimit && compareDates(activity.endDate)) {
                  setSelectedActivity(activity);
                  setShowModal(true);
                } else {
                  notifyAvailable();
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  )
};
